* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

.form-container {
  padding: 10px;
  padding-top: 20px;
  background-color: var(--tg-theme-secondary-bg-color);
  height: 100vh;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  border-radius: 10px;
}